import React from "react"
import styles from "./LoginForm.scss"
//import BirdBrain from './images/BirdBrain'
import Link from "gatsby-link"

export default ({ handleSubmit, handleUpdate, handleChange }) => (
  <section className="login-form">

    <form
      className={styles.form}
      method="post"
      onSubmit={event => {
        handleSubmit(event)
      }}
    >
    <div className="form-inner">
      <div className="message">{handleChange.message && <div className="inner">{handleChange.message}</div>}</div>
      <div className={`field${handleChange.username ? ' active' : ''}`}>
        <label htmlFor="username">Username</label>
        <input
          className={styles[`form__input`]}
          type="text"
          name="username"
          onChange={handleUpdate}
          placeholder="Email address"
          value={handleChange.username}
          required
        />
      </div>
      <div className={`field${handleChange.password ? ' active' : ''}`}>
        <label htmlFor="password">Password</label>
        <input
          className={styles[`form__input`]}
          type="password"
          name="password"
          onChange={handleUpdate}
          placeholder="Password"
          value={handleChange.password}
          required
        />
      </div>
      <button>{handleChange.loading ? 'loading' : 'Login' }</button>
      <Link className="sublink" to="/forgotten/">Forgotten password?</Link>
      </div>
    </form>
  </section>
)
