import React from "react"
import LoginForm from "./LoginForm"
import View from "./View"
import { navigate } from "gatsby"
import { setUser } from "./utils/auth"
import axios from 'axios';
import { isBrowser } from './utils/Functions';
import { isLoggedIn } from "./utils/auth"
import { Loading } from './images';
class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: ``,
      password: ``,
      message: ``,
      loading: true
    }
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
      message: '',
    })
  }

  handleSubmit(event, direct) {
    const { username, password } = event.target
    event.preventDefault()
    this.setState({loading: true, message: ''});

    const handleLogin = async () => {
      if (!isBrowser) return false
        const url = `${process.env.GATSBY_WP_ADDRESS}/api/auth/generate_auth_cookie/`
        //console.log('URL',url)
        try {
          const user = await axios.post(url, null, {params: {username: username.value, password: password.value, insecure: process.env.NODE_ENV ? 'cool' : '' }})

          if (user.data) {
            if (user.data.status === 'ok') {
              //console.log('user.data.user:',user.data.user)
              setUser({
                name: user.data.user.displayname,
                legalName: user.data.user.displayname,
                email: user.data.user.email,
                cookie: user.data.cookie,
                cookie_name: user.data.cookie_name,
              })
              navigate('/members/')
            }else{
              this.setState({loading: false, message: 'We were unable to find a user with that email address'});
              setTimeout(() => {
                this.setState({message: ''});
              }, 5000);
            }
          }
        } catch(e) {
          console.log('error:', e);
          this.setState({
            loading: false,
            message: 'Please contact the site admin.'
          });
        }
    }

    handleLogin()
  }

  componentDidMount() {
    setTimeout(() => {
      isLoggedIn() ? navigate(`/members/`) :
      this.setState({loading: false});
    }, 500);
  }

  render() {
    const { loading } = this.state
    return (
      !loading ?
      <div className="secure">
        <View title="Log In">
          <LoginForm
            handleUpdate={e => this.handleUpdate(e)}
            handleSubmit={e => this.handleSubmit(e)}
            handleChange={this.state}
          />
        </View>
      </div>
      :
      <div className="secure"><div className="loading"><Loading/></div></div>
    )
  }
}

export default Login
